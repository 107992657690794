import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import PageWrapper from '../components/pagewrapper/pageWrapper';

interface BookItem {
  name: string;
  amazonLink: string;
  finishedBook: boolean;
}

const bookItems: BookItem[] = [
  {
    name: 'Atomic Habits',
    amazonLink: 'https://www.amazon.com/Atomic-Habits-Proven-Build-Break/dp/0735211299',
    finishedBook: true,
  },
  {
    name: "Can't Hurt Me",
    amazonLink: 'https://www.amazon.com/Cant-Hurt-Me-Master-Your/dp/1544512287',
    finishedBook: true,
  },
  {
    name: 'Effective Java',
    amazonLink: 'https://www.amazon.com/Effective-Java-Joshua-Bloch/dp/0134685997',
    finishedBook: true,
  },
  {
    name: 'Design Patterns: Elements of Reusable Object-Oriented Software',
    amazonLink:
      'https://www.amazon.com/Design-Patterns-Object-Oriented-Addison-Wesley-Professional-ebook/dp/B000SEIBB8',
    finishedBook: true,
  },
  {
    name: '7 Habits of Highly Effective People',
    amazonLink: 'https://www.amazon.com/Habits-Highly-Effective-People-Powerful/dp/0743269519',
    finishedBook: true,
  },
  {
    name: 'Designing Data-Intensive Applications',
    amazonLink: 'https://www.amazon.com/Designing-Data-Intensive-Applications-Reliable-Maintainable/dp/1449373321',
    finishedBook: true,
  },
  {
    name: '12 Rules for Life',
    amazonLink: 'https://www.amazon.com/12-Rules-Life-Antidote-Chaos/dp/0345816021',
    finishedBook: true,
  },
  {
    name: 'Freakonomics',
    amazonLink: 'https://www.amazon.com/Freakonomics-Economist-Explores-Hidden-Everything/dp/0060731338',
    finishedBook: true,
  },
  {
    name: 'Digital Minimalism',
    amazonLink: 'https://www.amazon.com/Digital-Minimalism-Choosing-Focused-Noisy/dp/0525536515',
    finishedBook: true,
  },
  {
    name: "So Good They Can't Ignore You",
    amazonLink: 'https://www.amazon.com/Good-They-Cant-Ignore-You-ebook/dp/B0076DDBJ6',
    finishedBook: true,
  },
  {
    name: 'Principles',
    amazonLink: 'https://www.amazon.com/Principles-Life-Work-Ray-Dalio/dp/1501124021',
    finishedBook: true,
  },
  {
    name: 'How I Built This',
    amazonLink: 'https://www.amazon.com/How-Built-This-Unexpected-Entrepreneurs/dp/0358216761',
    finishedBook: false,
  },
  {
    name: 'Deep Work',
    amazonLink: 'https://www.amazon.com/Deep-Work-Focused-Success-Distracted/dp/1455586692',
    finishedBook: false,
  },
  {
    name: 'The Charisma Myth',
    amazonLink: 'https://www.amazon.com/Charisma-Myth-Science-Personal-Magnetism/dp/1591845947',
    finishedBook: false,
  },
  {
    name: 'What I Wish I Knew When I Was 20',
    amazonLink: 'https://www.amazon.com/What-Wish-Knew-When-Was/dp/0061735191',
    finishedBook: false,
  },
  {
    name: 'Exhalation',
    amazonLink: 'https://www.amazon.com/Exhalation-Stories-Ted-Chiang/dp/1101947888',
    finishedBook: false,
  },
];

export default class Books extends React.Component<any, any> {
  renderBookItem = (bookItem: BookItem) => {
    return (
      <div className={'book'} key={bookItem.name}>
        <input type="checkbox" disabled={true} checked={bookItem.finishedBook} aria-label="book" />
        <label>
          <a target="_blank" rel="noopener noreferrer" href={bookItem.amazonLink}>
            {bookItem.name}
          </a>
        </label>
      </div>
    );
  };

  renderBookList = () => {
    const bookList: JSX.Element[] = [];
    for (const bookItem of bookItems) {
      bookList.push(this.renderBookItem(bookItem));
    }
    return <div className={'book-list'}>{bookList}</div>;
  };

  render() {
    return (
      <PageWrapper activeItem={'books'}>
        <Grid centered={true}>
          <Grid.Column
            mobile={12}
            tablet={10}
            computer={8}
            largeScreen={8}
            widescreen={8}
            className={'page-wrapper-container'}
          >
            <h1 className={'page-wrapper-title'}>{'Books'}</h1>
            <p className={'hello'}>{"List of books I've read/currently reading:"}</p>
            {this.renderBookList()}
          </Grid.Column>
        </Grid>
      </PageWrapper>
    );
  }
}
